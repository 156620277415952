import "./assets/styles/master.css";
import "./assets/plugin/bootstrap/css/bootstrap.min.css";
import "popper.js";
import Nav from "./layouts/header/Nav.tsx";
import Home from "./components/home.tsx";
import Contact from "./components/contact.tsx";
import Services from "./components/services.tsx";
import Works from "./components/works.tsx";
import About from "./components/about.tsx";
import Abonnement from "./components/abonement.tsx";
import Footer from "./layouts/footer/footer.tsx"
function App() {
  return (
    <div className="App">
      <Nav />
      
        <Home />
        <About />
        <Services />
        <Works />
        <Abonnement/>
        <Contact />
        <Footer/>
    </div>
  );
}

export default App;
