import React from "react";

const Footer = () => {
  const iconW = {
    fontSize: "35px",
    color: "green", // Couleur verte pour l'icône WhatsApp
    marginRight: "10px",
  };

  const iconM = {
    fontSize: "35px",
    color: "white", // Couleur blanche pour l'icône mail
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {" "}
          {/* Ajout de justify-content-center pour centrer les icônes */}
          <div className="col-md-6 m-10px-tb text-center">
            <a href="whatsapp://send?phone=33753669354&text=Bonjour%20j'ai%20consulté%20votre%20site%20et%20j'aimerai%20avoir%20plus%20d'information">
              <i className="fa-brands fa-whatsapp" style={iconW}></i>
            </a>
            <a className="icon-footer" href="mailto:developweb770@gmail.com">
              <i className="fa-solid fa-envelope" style={iconM}></i>
            </a>
          </div>
          <div className="col-md-6 m-10px-tb text-center text-md-right">
            <p>© 2023 copyright Designed by Ethan Karoubi</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
