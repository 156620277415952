import React from "react";

const Contact = () => {
  return (
    <section id="contactus" className="section bg-contact-us">
      <div className="container" id="cont-contact">
        <div className="row sm-m-25px-b m-35px-b">
          <div className="col-md-12">
            <div className="section-title" id='center-text'>
              <h3 className="dark-color text-uppercase text-center">
                Contactez-Nous!
              </h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 m-15px-tb m-35px-b">
            <div className="contact-info media box-shadow">
              <div className="icon">
                <a
                  className="whatsapp-icon"
                  href="whatsapp://send?phone=33753669354&text=Bonjour%20j'ai%20consulté%20votre%20site%20et%20j'aimerai%20avoir%20plus%20d'information"
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </div>
              <div className="media-body">
                <h6 className="dark-color font-alt">Whatsapp:</h6>
                <p>Office: +33753669354</p>
              </div>
            </div>

            <div className="contact-info media box-shadow">
              <div className="icon">
                <a
                  className="whatsapp-icon"
                  href="mailto:developweb770@gmail.com"
                >
                  <i className="fa-solid fa-envelope"></i>
                </a>
              </div>
              <div className="media-body">
                <h6 className="dark-color font-alt">E-Mail:</h6>
                <p>developweb770@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
