import React from 'react'

const Services = () => {
  return (
    <section id="services" className="section gray-bg">
      <div className="container">
        <div className="row sm-m-25px-b m-35px-b">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="dark-color text-uppercase">Nos Services</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-1 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-face-smile fa-lg"></i>
              </div>
              <div className="feature-content">
                <h5 className="my-5">Site statique</h5>
                <p>
                  Un site statique fonctionne comme une vitrine en ligne pour
                  présenter votre entreprise ou commerce à vos clients
                  potentiels, offrant une visibilité constante sur votre
                  activité, renforçant ainsi votre présence sur le web et
                  permettant aux visiteurs de découvrir facilement votre offre,
                  vos produits et vos services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-1 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-face-grin-stars fa-lg"></i>
              </div>
              <div className="feature-content">
                <h5 className="my-5">Site dynamique</h5>
                <p>
                  Un site dynamique se distingue du site statique par sa
                  richesse de fonctionnalités, notamment la possibilité de
                  réaliser des paiements en ligne et d'intégrer des animations
                  interactives comme pour un site E-commerce, offrant ainsi une
                  expérience plus complète et engageante pour les utilisateurs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-1 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-list fa-lg"></i>
              </div>
              <div className="feature-content">
                <h5>CRM</h5>
                <p>
                  Un CRM est un système qui aide les entreprises à gérer et à
                  optimiser leurs relations avec les clients. Il centralise les
                  informations clients, historiques d'interaction, et permet
                  d'automatiser et personnaliser les communications, afin
                  d'améliorer la satisfaction des clients, la rétention, et les
                  ventes. En somme, il facilite la gestion et le suivi des
                  interactions avec les clients pour renforcer la relation et
                  stimuler la croissance de l'entreprise.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-2 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-computer-mouse"></i>
              </div>
              <div className="feature-content">
                <h5 className='my-5'>Design unique</h5>
                <p>
                  Notre entreprise vous présente un design spécialement conçu
                  pour correspondre parfaitement à votre entreprise, afin de
                  mieux répondre à vos besoins et satisfaire vos attentes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-2 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-phone"></i>
              </div>
              <div className="feature-content">
                <h5>Responsive</h5>
                <p>
                  Notre objectif principal est de concevoir un site web qui
                  offre une expérience utilisateur exceptionnelle, en
                  garantissant que chaque visiteur, qu'il s'agisse d'un
                  utilisateur d'ordinateur de bureau ou d'un utilisateur de
                  téléphone portable.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-2 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-shield-halved"></i>
              </div>
              <div className="feature-content">
                <h5 className='my-5'>Sécurité</h5>
                <p>
                  En concevant nos sites internet de A à Z en code pur, nous
                  mettons un point d'honneur à garantir la sécurité de votre
                  site de toute personnes malveillante ou de virus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;