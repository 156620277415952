import React from 'react'
import logo from "../assets/images/logo.png";

const About = () => {
  return (
    <section id="about" className="section gray-bg">
      <div className="container">
        <div className="row sm-m-25px-b m-35px-b">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="dark-color text-uppercase">ABOUT US</h3>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5 m-15px-tb">
            <div className="about-me-img box-shadow">
              <img src={logo} alt="Ma Photo" />
              {/* <div className="nav social-icon">
                {/* <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a> */}
              {/* </div>  */}
            </div>
          </div>
          <div className="col-lg-7 m-15px-tb">
            <div className="about-me">
              <h4>DevelopWeb</h4>
              <p id="text-about-us">
                Notre entreprise se distingue par son engagement à offrir des
                services de développement de sites internet de haute qualité à
                des prix abordables. Avec une équipe d'experts techniques et
                créatifs, nous sommes déterminés à créer des sites web sur
                mesure qui répondent parfaitement à vos besoins en ligne, que ce
                soit pour des sites vitrines élégants, des boutiques en ligne
                performantes ou des plateformes web complexes. Notre approche
                axée sur l'efficacité et le professionnalisme nous permet de
                garantir des solutions web exceptionnelles, tout en respectant
                votre budget. Confiez-nous votre projet, et nous le
                transformerons en une réalité numérique qui non seulement
                impressionnera votre public, mais optimisera également votre
                présence en ligne. Avec nous, la qualité et l'accessibilité vont
                de pair pour vous offrir un rapport qualité-prix imbattable.
              </p>
              <p>
                Notre expertise s'étend à la création de divers types de sites
                web, notamment des sites e-commerce, des sites vitrines et des
                CRM, avec un accent particulier sur la conception responsive et
                des designs uniques. Lorsque nous concevons des sites
                e-commerce, notre objectif est de fournir à votre entreprise une
                plateforme en ligne robuste, sécurisée et conviviale, qui vous
                permettra de vendre vos produits ou services efficacement. Pour
                les sites vitrines, nous mettons en place des pages web
                élégantes et informatives qui reflètent l'identité de votre
                entreprise tout en offrant une expérience utilisateur
                exceptionnelle. En ce qui concerne les CRM (Customer
                Relationship Management), nous créons des solutions sur mesure
                pour vous aider à gérer vos relations clients de manière
                efficace. Tous nos projets sont conçus pour être totalement
                responsives, ce qui signifie qu'ils s'adaptent parfaitement à
                tous les appareils, du bureau aux smartphones, garantissant
                ainsi une accessibilité optimale pour vos visiteurs. De plus,
                notre équipe de designers talentueux crée des designs uniques et
                personnalisés pour chaque projet, afin que votre site se
                démarque de la concurrence et reflète parfaitement votre
                identité de marque.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About