import React from 'react'
import projet from "../assets/images/projet.png"
import projet2 from "../assets/images/projet2.png";

const Works = () => {
  return (
    <section id="work" className="section">
      <div className="container">
        <div className="row sm-m-25px-b m-35px-b">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="dark-color text-uppercase">Portfolio</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="portfolio-content lightbox-gallery">
          <div className="grid-item product branding">
            <div className="portfolio-box-01">
              <div className="portfolio-img">
                <img src={projet} alt="Ma Photo" />
              </div>
              <div className="portfolio-info">
                <h5>Nos Projets</h5>
                <span>Design</span>
              </div>
            </div>
          </div>
          <div className="grid-item product branding">
            <div className="portfolio-box-01">
              <img src={projet2} alt="Ma Photo" />
              <div className="portfolio-img"></div>
              <div className="portfolio-info">
                <h5>Nos Projets</h5>
                <span>Design</span>
              </div>
              <a className="link-overlay" href="https://nlt-conseil.com/"></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Works