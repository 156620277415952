import React from "react";

const Home = () => {
  return (
    <section
      id="home"
      className="home-banner-01 bg-cover bg-center bg-no-repeat  bg-home"
    >
      <div className="container">
        <div className="row full-screen align-items-center p-100px-tb">
          <div className="col-md-6" id="block">
            <div className="ht-text">
              <h1>DevelopWeb</h1>
              <h2>Votre site web, votre identité en ligne</h2>
              <div className="btn-bar go-to">
                <a className="m-btn m-btn-theme" href="#work">
                  Nos travaux
                </a>
                <a className="m-btn m-btn-t-theme" href="#contactus">
                  Contactez Nous
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="go-to go-to-next">
        <a href="#about">
          <span></span>
        </a>
      </div>
    </section>
  );
};

export default Home;
