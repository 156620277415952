import React from 'react'


const Nav = () => {
  return (
    <header className="header-left" id="navbar-collapse-toggle">
      <div className="navbar-brand">
        <a className="logo-text" href="index.html">
          DevelopWeb
        </a>
      </div>
      <ul className="nav">
        <li>
          <a className="nav-link" href="#home">
            <i className="fas fa-house-damage"></i>
            <span>Home</span>
          </a>
        </li>
        <li>
          <a className="nav-link" href="#about">
            <i className="far fa-address-card"></i>
            <span>About Us</span>
          </a>
        </li>
        <li>
          <a className="nav-link" href="#services">
            <i className="fas fa-concierge-bell"></i>
            <span>Services</span>
          </a>
        </li>
        <li>
          <a className="nav-link" href="#work">
            <i className="fas fa-briefcase"></i>
            <span>Portfolio</span>
          </a>
        </li>
        <li>
          <a className="nav-link" href="#abonnement">
            <i className="fa-solid fa-bolt"></i>
            <span>Abonnement</span>
          </a>
        </li>
        <li>
          <a className="nav-link" href="#contactus">
            <i className="fas fa-id-card-alt"></i>
            <span>Contact</span>
          </a>
        </li>
        <li>
          <a className="nav-link" href="whatsapp://send?phone=33753669354&text=Bonjour j'ai consulté votre site et j'aimerai avoir plus d'information">
            <i className="fa-brands fa-whatsapp"></i>
            <span>Whatsapp</span>
          </a>
        </li>
        <li>
          <a className="nav-link" href="mailto:developweb770@gmail.com">
            <i className="fa-solid fa-envelope"></i>
            <span>E-Mail</span>
          </a>
        </li>
      </ul>
    </header>
  );
}

export default Nav