import React from "react";

const Abonnement = () => {
  return (
    <section id="abonnement" className="section gray-bg">
      <div className="container">
        <div className="row sm-m-25px-b m-35px-b">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="dark-color text-uppercase">Abonnement</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-3 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-shield-halved"></i>
              </div>
              <h4>Lite</h4>
              <div className="feature-content">
                <ul>
                  <li> Possibilité de changement de texte une fois par mois</li>
                  <li>Possibilité de changement de photo une fois par mois</li>
                  <li>Assistance en cas de problème technique </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-4 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-star"></i>
              </div>
              <div className="feature-content">
                <h4>Premium</h4>
                <ul>
                  <li>Possibilité de changement de texte à la demande</li>
                  <li>Possibilité de changement de photo à la demande</li>
                  <li>Assistance en cas de problème technique </li>
                  <li>Possibilité de rajout de page et formulaire</li>
                  <li>Service clients 24/24 7/7</li>
                  <li>20% de réduction sur chaque module rajouté</li>
                  <li>20% de réduction sur le prochain site internet ou CRM</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 m-15px-tb">
            <div className="feature-box-3 box-shadow-lg">
              <div className="icon">
                <i className="fa-solid fa-shield"></i>
              </div>
              <div className="feature-content">
                <h4>Regular</h4>
                <ul>
                  <li>
                    Possibilité de changement de texte trois fois par mois
                  </li>
                  <li>
                    Possibilité de changement de photo trois fois par mois
                  </li>
                  <li>Assistance en cas de problème technique </li>
                  <li>Possibilité de rajout de page et formulaire</li>
                  <li>Service clients 24/24 7/7</li>
                  <li>10% de réduction sur chaque module rajouté</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Abonnement;
